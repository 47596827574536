import React from 'react';
import classes from './Projects.module.css';
import airportnew from '../images/airportnew.png';
import ooengine from '../images/ooengine.png';
import notes_summarizer from '../images/notes_summarizer.png';
import telegram_links_bot from '../images/telegram_links_bot.png';
import exercise_counter from '../images/exercise_counter.png';
import matrix_path from '../images/matrix_path.png';
import ScrollAnimation from 'react-animate-on-scroll';
import 'animate.css/animate.min.css';

// Web projects
const cardItem = [
  {
    link: 'https://github.com/rushmash91/matrix_path',
    title: 'Matrix Navigation System',
    techStack: 'Tech Stack: Python',
    desc: 'This Python package contains a Matrix class that models a grid of cells, some of which are accessible (represented by "0") and some are walls (represented by "|"). A start point ("*") and an end point ("#") are also part of the matrix. The class provides functionality for navigating this matrix with movement methods: up, down, left, and right.',
    image: matrix_path
  },
  {
    link: 'https://github.com/rushmash91/Notes-Summarizer',
    title: 'Notes-Summarizer',
    techStack: 'Tech Stack: Python, Natural Language Processing',
    desc: 'A tool for summarizing notes. It removes all stopwords, performs stemming and part of speech tagging to obtain nouns, creates term frequency and inverse document frequency matrix, scores sentences and extracts those above a threshold score. Sentences are then arranged in the chronological order of their original text.',
    image: notes_summarizer
  },
  {
    link: 'https://github.com/rushmash91/Exercise-Counter',
    title: 'Exercise Counter with Pose Estimation',
    techStack: 'Tech Stack: Python, OpenCV, Mediapipe',
    desc: 'This project uses the power of computer vision to track and count various exercises in real-time. It is built using the mediapipe library for pose estimation and OpenCV for image processing. Features include real-time exercise check and counter for push-ups, squats, bicep curls and pull-ups, visual feedback on the user\'s form, and progress bar indicating the completion of each exercise.',
    image: exercise_counter
  },
  {
    link: 'https://github.com/rushmash91/telegram_links_bot',
    title: 'Telegram Links Bot',
    techStack: 'Tech Stack: Python, SQL',
    desc: 'A Telegram bot used for sending emails via Chatbot and storing important links into a SQL database to be retrieved whenever needed. The bot has commands for adding, removing and viewing links in the database, and for starting an email event.',
    image: telegram_links_bot
  },
  {
    link: 'https://github.com/rushmash91/OOEngine',
    title: 'OOEngine: Django Movie Search Engine',
    techStack: 'Tech Stack: Python, Django, SQLite',
    desc: 'A simple, lightweight, and efficient movie search engine built using Django and SQLite. The engine allows users to search for movies in a database.',
    image: ooengine
  },
  {
    link: 'https://github.com/rushmash91/AirportNew',
    title: 'Django Airport Management System',
    techStack: 'Tech Stack: Python, Django, MySQL',
    desc: 'A comprehensive airport management system built using Django and MySQL, providing efficient and streamlined operations for airports. It offers features to manage flights, passengers, staff, and other airport operations.',
    image: airportnew
  }
];


export default function Projects() {
  const getProjectCard = (cardItem) => {
    return (
      <li key={cardItem.title}>  {/* Here we use the title as key */}
        <a href={cardItem.link} className={classes.card} target='_blank'>
          <img src={cardItem.image} className={classes.card__image} alt='' />
          <div className={classes.card__overlay}>
            <div className={classes.card__header}>
              <svg className={classes.card__arc} xmlns='http://www.w3.org/2000/svg'>
                <path />
              </svg>
              <div className={classes.card__header_text}>
                <h3 className={classes.card__title}>{cardItem.title}</h3>
                <span className={classes.card__status}>{cardItem.techStack}</span>
              </div>
            </div>
            <p className={classes.card__description}>{cardItem.desc}</p>
          </div>
        </a>
      </li>
    );
  };

  return (
    <div className={classes.box} id='projects'>
      <ScrollAnimation
        offset={0}
        animateIn='fadeInLeft'
        duration={2.4}
        animateOnce={true}
        initiallyVisible={true}
      >
        <span className={classes.head}>MY WORK</span>
        <h2 className={classes.heading}>PROJECTS</h2>
        <ul className={classes.cards}>
          {cardItem.map((item) => {
            return getProjectCard(item);
          })}
        </ul>
      </ScrollAnimation>
    </div>
  );
}

