import React, { Component, useState, useEffect } from "react";
import axios from "axios";
import classes from "./About.module.css";
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";
import { HashLink as Link } from "react-router-hash-link";
import Switch from "react-switch";

// First component (App)
function App() {
  const [data, setData] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [isWriting, setWriting] = useState(true);

  const getFromOpenAI = async () => {
    setLoading(true);
        const response = await axios.get('https://zzf5gh47xktzr4noufbsb275pm0mwlcf.lambda-url.us-east-1.on.aws/');
    if (response.data.error) {
      console.error("Error fetching data:", response.data.error);
      setLoading(false);
    } else {
      setData(response.data.text);
      setLoading(false);
      setWriting(false);
    }
  };

  useEffect(() => {
    getFromOpenAI();
  }, []);

  return (
    <div className={classes.About}>
      {isWriting && <h4>Chat GPT is writing a new intro...</h4>}
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <div dangerouslySetInnerHTML={{ __html: data }}></div>
      )}
    </div>
  );
}

// Second component (About)
class About extends Component {
  render() {
    return (
      <div className={classes.About}>
        <p>
          I'm <b>Arush Sharma</b>. I hold a{" "}
          <Link smooth to="/#education">
            <b>Master's Degree in Computer Science</b>
          </Link>{" "}
          from
          <b>
            {" "}
            <a
              className={classes.link}
              target="_blank"
              href="https://www.utdallas.edu/"
            >
              The University of Texas at Dallas
            </a>
          </b>
          . My expertise lies in Python, JavaScript, AWS, and SQL, which I am
          enthusiastic about utilizing to develop innovative technologies.
        </p>
        <p className={classes.br}>
          As a <b>Software Engineer</b> at Cadence Design Systems and Sicuro
          Infosystem, I gained valuable experience in various domains. I am
          proficient in <b>AWS</b> and other cloud platforms, with a strong
          command over databases such as MySQL, MongoDB, PostgreSQL, and NoSQL.
          Additionally, I am well-versed in web development tools like Django,
          Flask, React, and Node.js. I always embrace opportunities to learn and
          adapt well to emerging technological trends.
        </p>
        <p className={classes.br}>
          Beyond my professional endeavors, I have contributed to captivating
          projects such as a Notes Summarizer and a Telegram Links Bot. These
          ventures have enhanced my skills and resulted in tangible benefits for
          users, including significant time savings and improved data
          management.
        </p>
        <p className={classes.br}>
          As an{" "}
          <Link smooth to="/#interest">
            <b>AWS Certified Developer Associate</b>
          </Link>
          , I derive satisfaction from tackling intricate problems and
          optimizing systems to achieve superior performance. If you would like
          to explore more about my work or get in touch, please don't hesitate
          to contact me.
        </p>
      </div>
    );
  }
}

export default function MasterComponent() {
  const [toggleState, setToggleState] = useState(false);

  const handleToggle = (checked) => {
    setToggleState(checked);
  };

  return (
    <div className={classes.box} id="about">
      <ScrollAnimation
        offset={0}
        animateIn="fadeInLeft"
        duration={2.4}
        animateOnce={true}
        initiallyVisible={true}
      >
        <span className={classes.head}>
          {toggleState
            ? "Switch to Regular Mode    "
            : "Switch to Poetry Mode    "}
          <Switch
            onChange={handleToggle}
            checked={toggleState}
            offColor="#808080"
            onColor="#86d3ff"
            onHandleColor="#2693e6"
            handleDiameter={12}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
            height={10}
            width={35}
            className="react-switch"
            id="material-switch"
          />
        </span>
        <h2 className={classes.heading}>
          {toggleState ? "Can chatgpt write a poem about me?" : "Who Am I?"}
        </h2>
        {toggleState ? <App /> : <About />}
      </ScrollAnimation>
    </div>
  );
}
