import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import datePDF from './date.pdf';

function Megha() {

  const [word, setWord] = useState(''); 
  const history = useHistory();

  async function checkWord() {

    if(word === 'fukuro') {

      // Create a dynamic <a> and click to download
      let link = document.createElement('a');
      link.href = datePDF;
      link.download = datePDF; 
      document.body.appendChild(link);
      link.click();
      link.remove();

      history.push('/');

    } else {
      setWord('');
    }
  }

  return (
    <div style={{ 
        backgroundColor: 'black', 
        color: 'white',
        textAlign: 'center' 
      }}>
      
      <h1>Hi Cutie❤️</h1>
      <h2>safe word?</h2>

      <input 
        value={word}
        type="password"
        onChange={e => setWord(e.target.value)} />
  
      <button onClick={checkWord}>Check</button>

    </div>
  );

}

export default Megha;

