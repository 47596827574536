import React, { Component } from 'react';
import './Sidebar.css';
import { HashLink as Link } from 'react-router-hash-link';
import logo from '../images/sam.jpg';
import { FiDownload } from 'react-icons/fi';
import resume from '../images/resume.pdf';

class Sidebar extends Component {
  render() {
    return (
      <div className='sidebar'>
        <div style={{ color: 'black', fontWeight: 'bold' }} className='tagtop'></div>
        <div style={{ color: 'black', fontWeight: 'bold' }} className=''></div>
        <div style={{ color: 'black', fontWeight: 'bold' }} className=''>
          <br />
          <br />
          <br />
        </div>
        <h1>
          <Link smooth to='/#start' className='name-font'>
            Arush Sharma
          </Link>
        </h1>

        <img src={logo} />

        <ul className='sidebar-nav'>
          <li className='sidebar-nav-items'>
            <a
              href={resume}
              className='links'
              download='Arush_Sharma_Resume.pdf'
            >
              Resume <FiDownload />
            </a>
          </li>
          <br />
          <br />
          <li className='sidebar-nav-items'>
            <Link smooth to='/#about' className='links'>
              About
            </Link>
          </li>

          <li className='sidebar-nav-items'>
            <Link smooth to='/#education' className='links'>
              Journey
            </Link>
          </li>

          <li className='sidebar-nav-items'>
            <Link smooth to='/#projects' className='links'>
              Projects
            </Link>
          </li>

          <li className='sidebar-nav-items'>
            <Link smooth to='/#interest' className='links'>
              Interest
            </Link>
          </li>

          <li className='sidebar-nav-items'>
          <Link smooth to='/#contact' className='links'>
            Send Me a Message
          </Link>
          </li>
        </ul>

        <div className='flip-card-back'>
          <ul className='sidebar-nav'>
            <li className='sidebar-nav-icons'>
              <a
                href='https://github.com/rushmash91'
                rel='opener noreferrer'
                target='_blank'
                className='fa fas fa-github fa-lg icon-black'
              ></a>
            </li>
            <li className='sidebar-nav-icons'>
              <a
                href='https://www.linkedin.com/in/arushsharma/'
                rel='opener noreferrer'
                target='_blank'
                className='fa fas fa-linkedin fa-lg icon-black'
              ></a>
            </li>
            <li className='sidebar-nav-icons'>
              <a
                href='mailto:arushsharma91@outlook.com'
                rel='opener noreferrer'
                target='_blank'
                className='fa fas fa-envelope fa-lg icon-black'
              ></a>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default Sidebar;
