import React, { Component } from 'react';
import classes from './Education.module.css';
import ScrollAnimation from 'react-animate-on-scroll';
import 'animate.css/animate.min.css';
import { MdSchool } from 'react-icons/md';
import { MdWork } from 'react-icons/md';
import { FaSchool } from 'react-icons/fa';

class Education extends Component {
  render() {
    return (
      <div className={classes.box} id='education'>
        <ScrollAnimation
          offset={0}
          animateIn='fadeInLeft'
          duration={2}
          animateOnce={true}
          initiallyVisible={true}
        >
<span className={classes.head}>MY JOURNEY</span>
<section className={classes.container}>
  <div className={classes.container_content}>
    <div className={classes.row}>
      <div className={classes.row_md_12}>
        <div className={classes.timeline_centered}>
        
          <ScrollAnimation
            offset={0}
            animateIn='fadeInLeft'
            duration={2.4}
            animateOnce={true}
            initiallyVisible={true}
          >
            <article className={classes.timeline_entry}>
              <div className={`${classes.timeline_icon} ${classes.timeline_icon_5}`}>
                <MdWork />
              </div>
              <div className={classes.label}>
                <h2>
                  Internship at Cadence Design Systems <span>May 2022 – August 2022</span>
                </h2>
                <p>
                  As a Software Engineer Intern, I was involved in various projects which enhanced verification throughput, improved workflows and revealed important product patterns. I collaborated with cross-functional teams to gather requirements and define project scope, significantly reducing project completion time.
                </p>
              </div>
            </article>
          </ScrollAnimation>

          <ScrollAnimation
            offset={0}
            animateIn='fadeInLeft'
            duration={2.4}
            animateOnce={true}
            initiallyVisible={true}
          >
            <article className={classes.timeline_entry}>
              <div className={classes.timeline_icon}>
                <MdSchool />
              </div>
              <div className={classes.label}>
                <h2>
                  Master's Degree at University of Texas <span>2021-2023</span>
                </h2>
                <p>
                  I pursued my Master of Science in Computer Science at the University of Texas at Dallas.
                </p>
              </div>
            </article>
          </ScrollAnimation>

          <ScrollAnimation
            offset={0}
            animateIn='fadeInLeft'
            duration={2.4}
            animateOnce={true}
            initiallyVisible={true}
          >
            <article className={classes.timeline_entry}>
              <div className={classes.timeline_icon}>
                <MdWork />
              </div>
              <div className={classes.label}>
                <h2>
                  Experience at Sicuro Infosystem <span>August 2020 – July 2021</span>
                </h2>
                <p>
                  During my tenure as a Software Engineer at Sicuro Infosystem, I increased data availability by using Scrapy crawlers on AWS EC2 and also reduced anomalies with the use of AWS Lambda Functions combined with a Pandas layer. I used unsupervised clustering to improve article classification, and implemented a scalable data loading process to enhance performance.
                </p>
              </div>
            </article>
          </ScrollAnimation>

          <ScrollAnimation
            offset={0}
            animateIn='fadeInLeft'
            duration={2.4}
            animateOnce={true}
            initiallyVisible={true}
          >
            <article className={classes.timeline_entry}>
              <div className={`${classes.timeline_icon} ${classes.timeline_icon_2}`}>
                <MdWork />
              </div>
              <div className={classes.label}>
                <h2>
                  Experience at Conscript <span>August 2019 – August 2020</span>
                </h2>
                <p>
                  My role as a Software Engineer at Conscript allowed me to optimize web app performance by migrating to Django and AWS EC2, and enhance text classification accuracy through sentiment analysis and TF-IDF. I also integrated RESTful APIs for seamless data exchange and ensured safe and efficient code collaboration with Git and AWS CodeCommit.
                </p>
              </div>
            </article>
          </ScrollAnimation>

          <ScrollAnimation
            offset={0}
            animateIn='fadeInLeft'
            duration={2.4}
            animateOnce={true}
            initiallyVisible={true}
          >
            <article className={classes.timeline_entry}>
              <div className={classes.timeline_icon}>
                <MdWork />
              </div>
              <div className={classes.label}>
                <h2>
                  Experience at Mathura Public School <span>August 2017 – July 2019</span>
                </h2>
                <p>
                  As a <b>Software Engineer</b> at Mathura Public School, I led the development of a comprehensive school management web application using Django, serving over 2,000 students, faculty, and staff members. The web application was successfully deployed on AWS EC2 instances, optimizing database queries with Django ORM, and I developed role-based access control in the Django application. This resulted in significant administrative efficiency improvements and reduced unauthorized access incidents.
                </p>
              </div>
            </article>
          </ScrollAnimation>

          <ScrollAnimation
            offset={0}
            animateIn='fadeInLeft'
            duration={2.4}
            animateOnce={true}
            initiallyVisible={true}
          >
            <article className={classes.timeline_entry}>
              <div className={classes.timeline_icon}>
                <MdSchool />
              </div>
              <div className={classes.label}>
                <h2>
                  Undergraduation at Guru Gobind Singh Indraprastha University <span>2016-2020</span>
                </h2>
                <p>
                  I completed my Bachelor of Technology in Computer Science from Guru Gobind Singh Indraprastha University.
                </p>
              </div>
            </article>
          </ScrollAnimation>
        </div>
      </div>
    </div>
  </div>
</section>

        </ScrollAnimation>
      </div>
    );
  }
}

export default Education;
