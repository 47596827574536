import React, { Component } from 'react';
import classes from './Interest.module.css';
import ScrollAnimation from 'react-animate-on-scroll';
import 'animate.css/animate.min.css';

class Interest extends Component {
  render() {
    return (
      <div className={classes.box} id='interest'>
        <ScrollAnimation
          offset={0}
          animateIn='fadeInLeft'
          duration={2.4}
          animateOnce={true}
          initiallyVisible={true}
        >
          <span className={classes.head}>Certifications</span>
          <h2 className={classes.heading}>MY AWS CERTIFICATIONS</h2>
          <div className={classes.Interest}>
            <ScrollAnimation
              offset={0}
              animateIn='fadeInLeft'
              duration={2.4}
              animateOnce={true}
              initiallyVisible={true}
            >
              <div className={classes.web}>
                <h3>AWS Certified Developer - Associate</h3>
                <p>
                  Earned the AWS Certified Developer - Associate certification, demonstrating proficiency in developing and maintaining AWS applications using various AWS services and tools.
                </p>
                <a
                  href='https://www.credly.com/badges/74ce1071-6596-4cc9-923e-0d790cc414f9/public_url'
                  rel='opener noreferrer'
                  target='_blank'
                >
                  View Certification
                </a>
              </div>
            </ScrollAnimation>
            <ScrollAnimation
              offset={0}
              animateIn='fadeInLeft'
              duration={2.4}
              animateOnce={true}
              initiallyVisible={true}
            >
              <div className={classes.web}>
                <h3>AWS Cloud Practitioner</h3>
                <p>
                  Achieved the AWS Cloud Practitioner certification, validating foundational knowledge of AWS cloud concepts, services, security, and architectural best practices.
                </p>
                <a
                  href='https://www.credly.com/badges/188f98c8-d1e3-4519-a816-f44b033b70b4/public_url'
                  rel='opener noreferrer'
                  target='_blank'
                >
                  View Certification
                </a>
              </div>
            </ScrollAnimation>
          </div>
        </ScrollAnimation>

        <ScrollAnimation
          offset={0}
          animateIn='fadeInLeft'
          duration={2.4}
          animateOnce={true}
          initiallyVisible={true}
        >
          <span className={classes.head}>Publications</span>
          <h2 className={classes.heading}>SOME SIGNIFICANT WORK I HAVE CONTRIBUTED TO</h2>
          <div className={classes.Interest}>
            <ScrollAnimation
              offset={0}
              animateIn='fadeInLeft'
              duration={2.4}
              animateOnce={true}
              initiallyVisible={true}
            >
              <div className={classes.web}>
                <h3>Text Summarization Technique</h3>
                <p>
                  Co-authored a chapter on text summarization techniques in the book "Multimodal Biometric Systems." Discussed and evaluated various extraction-based summarization algorithms.
                </p>
                <a
                  href='https://www.taylorfrancis.com/chapters/edit/10.1201/9781003138068-7/evaluation-text-summarization-technique-manju-khari-renu-dalal-arush-sharma-avinash-dubey?context=ubx&refId=a7c13bbd-484d-4c1d-a89a-d60db79495d7'
                  rel='opener noreferrer'
                  target='_blank'
                >
                  Read More
                </a>
              </div>
            </ScrollAnimation>
            <ScrollAnimation
              offset={0}
              animateIn='fadeInLeft'
              duration={2.4}
              animateOnce={true}
              initiallyVisible={true}
            >
              <div className={classes.web}>
                <h3>Matrix Path Python Library</h3>
                <p>
                  Developed a Python library to solve the problem of finding a path through a matrix. This library can be used for various applications like games, routing, and more.
                </p>
                <a
                  href='https://pypi.org/project/matrix-path/'
                  rel='opener noreferrer'
                  target='_blank'
                >
                  View Library
                </a>
              </div>
            </ScrollAnimation>
          </div>
        </ScrollAnimation>
      </div>
    );
  }
}

export default Interest;
