import React, { useState } from 'react';
import './ContactForm.css';

function ContactForm() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('name', name);
    formData.append('email', email);
    formData.append('message', message);

    try {
      const response = await fetch('https://formspree.io/f/xbjvjeel', {
        method: 'POST',
        body: formData,
        headers: {
          Accept: 'application/json',
        },
      });

      if (response.ok) {
        // Successful form submission
        alert('Thank you for your message!');
        setName('');
        setEmail('');
        setMessage('');
      } else {
        // Failed form submission
        alert('Failed to send message. Please try again later.');
      }
    } catch (error) {
      // Network error
      alert('An error occurred. Please try again later.');
    }
  };

  return (
    <div className="ContactForm" id='contact'>
      <h2>Let's Connect!</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">Name:</label>
          <input
            type="text"
            id="name"
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="message">Message:</label>
          <textarea
            id="message"
            name="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
          ></textarea>
        </div>

        <input
          type="hidden"
          name="_subject"
          value="New submission from {{ name }}"
        />

        <button type="submit">Submit</button>
      </form>
    </div>
  );
}

export default ContactForm;
